@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

@keyframes initialLoaderAnimation {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    opacity: 0.75;
  }
}

.loadingInContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: initialLoaderAnimation 0.8s cubic-bezier(0.53, 0.22, 0.85, 0.58) 0s infinite alternate;
}
