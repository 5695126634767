@media only screen and (max-width: 768px) {
    .commission-home-content {
        width: 50px !important;
        border-radius: 3px;
        box-shadow: 0 1px 3px 0 rgba(0,0,0,0.16), 0 1px 2px 0 rgba(0,0,0,0.15);
    }
}

/* Home */

.commission-page {
    padding: 16px 0 16px 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.commission-page-content {
    width: 70% !important;
}

/* CardSection */

a.vsx-card-clickable {
    text-decoration: none;
    background-color: #f4faf7;
}


.vsx-box > a {
    text-decoration: none;
}