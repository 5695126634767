.vsx-single-file-upload-button {
    display: flex !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    max-width: none !important;
    margin: 0 !important;
}
   
.vsx-single-file-upload-placeholder {
    display: flex !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    max-width: none !important;
    margin: 0 !important;
}
